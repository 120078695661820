<template>
  <div id="app">
    <Nav></Nav>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
  import Nav from '@/components/nav.vue'
  import Footer from '@/components/footer.vue'
  export default {
    components:{Nav,Footer}
  }
</script>
<style lang="scss">
@import url('./assets/css/webent.css');
  @import url('./assets/css/reset.css');
  @import url('./assets/css/am.css');
  
</style>
