import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/index.vue'),
    meta:{title:'网站首页'}
  },
  {
    path: '/site/introduction',
    name: 'introduction',
    component: () => import('@/views/introduction.vue'),
    meta:{title:'品牌介绍'}
  },
  {
    path: '/site/news',
    name: 'news',
    component: () => import('@/views/news.vue'),
    meta:{title:'新闻动态'}
  },
  {
    path: '/site/newsDetail/:id',
    name: 'newsDetail',
    component: () => import('@/views/newsDetail.vue'),
    meta:{title:'新闻动态详情'}
  },
  {
    path: '/site/itemswu',
    name: 'itemswu',
    component: () => import('@/views/itemswu.vue'),
    meta:{title:'昇昱服务'}
  },
  {
    path: '/site/opportunities',
    name: 'opportunities',
    component: () => import('@/views/opportunities.vue'),
    meta:{title:'加入我们'}
  },
  {
    path: '/site/contactus',
    name: 'contactus',
    component: () => import('@/views/contactus.vue'),
    meta:{title:'联系我们'}
  },
  {
    path: '/site/agent',
    name: 'agent',
    component: () => import('@/views/subPages/agent.vue'),
    meta:{title:''}
  },
  {
    path: '/site/apartment',
    name: 'apartment',
    component: () => import('@/views/subPages/apartment.vue'),
    meta:{title:''}
  },
  {
    path: '/site/spacelease',
    name: 'spacelease',
    component: () => import('@/views/subPages/spacelease.vue'),
    meta:{title:''}
  },
  {
    path: '/site/office',
    name: 'office',
    component: () => import('@/views/subPages/office.vue'),
    meta:{title:''}
  },
  {
    path: '/site/rentals',
    name: 'rentals',
    component: () => import('@/views/subPages/rentals.vue'),
    meta:{title:''}
  },
  {
    path: '/site/businesscenter',
    name: 'businesscenter',
    component: () => import('@/views/subPages/businesscenter.vue'),
    meta:{title:''}
  },
  {
    path: '/site/join',
    name: 'join',
    component: () => import('@/views/join.vue'),
    meta:{title:''}
  },
  {
    path: '/site/joinanother/:id',
    name: 'joinanother',
    component: () => import('@/views/joinanother.vue'),
    meta:{title:''}
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: '/site',
  routes
})

export default router
