<template>
    <header>
        <div class="header wp clear">
        <div class="header_l fl">
            <router-link to="/"><img src="../assets/images/logo.png" alt=""></router-link>
        </div>
        <div class="header_c fl">
            <ul>
                <li :class="{'hea_on':activeIndex==index}" @click="routerTo(index)" v-for="(item,index) in navList" :key="index">
                    <router-link :to="item.router">{{ item.name }}</router-link>
                </li>
            </ul>
        </div>
        <div class="header_r fr">
            <span><img src="../assets/images/dh.png" alt=""><i>服务热线：029-88858777</i></span>
        </div>   
             <!--手机导航-->
        <div class="nav-img am-btn am-btn-success fr" data-am-offcanvas="{target: '#Nav'}"><img
                    src="../assets/images/nav-img.png" alt=""></div>        <!-- 侧边栏内容 -->
        <div id="Nav" class="am-offcanvas ">
            <div class="am-offcanvas-bar am-offcanvas-bar-flip">
                <div class="am-offcanvas-content">
                    <ul>                       
                        <li class="" @click="routerTo(index)" v-for="(item,index) in navList" :key="index">
                            <router-link :to="item.router">{{ item.name }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </header>
</template>


<script>
    export default{
        data(){
            return{
                navList:[
                    {
                        name:'网站首页',
                        router:'/'
                    },
                    {
                        name:'品牌介绍',
                        router:'/site/introduction'
                    },
                    {
                        name:'昇昱服务',
                        router:'/site/itemswu'
                    },
                    {
                        name:'加入我们',
                        router:'/site/opportunities'
                    },
                    {
                        name:'新闻动态',
                        router:'/site/news'
                    },
                    {
                        name:'联系我们',
                        router:'/site/contactus'
                    }
                ],
                activeIndex:0,
            }
        },
        watch:{
        '$route':{
            handler(to,from){
                this.activeIndex = this.navList.findIndex(el=>{
                    return el.router==to.path
                })
            },
            immediate:true
            }
        },
        methods:{
            routerTo(index){
                this.activeIndex = index;
            }
        }
    }
</script>